// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
window.jQuery = $;
window.$ = $;



import 'select2';
require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require("jquery")
// require("jquery-ui")

require("jquery-ui-dist/jquery-ui")
//include lib
require("lib/bundle.js");

// common libs
require("lib/jquery.maskedinput.js")
require("lib/jquery.toast")

// crop image feature
require("lib/jquery.Jcrop.min.js")

require("lib/crop_usage.js")
require("lib/bootstrap.bundle.js")
require("lib/summernote.js")
require("lib/bootstrap-toggle.min.js")
// require("lib/jquery-ui.min.js")
require("lib/bootstrap-datepicker.min.js")
require("lib/jquery.croper.js")
require("lib/lightbox.js")
require("lib/lightbox-plus-jquery.js")
// require("lib/cable.js")
require("lib/room.js")
require("lib/allow_number.js")
require("lib/jquery.rainbowJSON.js")

require("treegrid/jquery.treegrid.min.js")
require("treegrid/bootstrap-table.min.js")
require("treegrid/bootstrap-table-treegrid.min.js")


import mediumZoom from 'medium-zoom'


global.moment = require('moment');
require('lib/tempusdominus-bootstrap-4.js')
require('lib/fullcalendar.min.js')
// import FullCalendar from 'lib/main.js'
// import datetimepicker from 'lib/tempusdominus-bootstrap-4.js';

import bootbox from 'bootbox/bootbox';

// import datepicker from 'bootstrap-datepicker';
window.bootbox = bootbox;

import ProgressiveWebApp from 'pwa-rails';

// const progressiveWebApp = new ProgressiveWebApp('pwa-sw.js');
/* window.jQuery('DOMContentLoaded', () => {
  if (document.body.dataset.environment != 'test') {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/pwa-sw.js')
        .then(registration => {
          // console.log('ServiceWorker registered: ', registration)
        });
    });
  }
}); */

// datepicker = $.fn.datepicker
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


$(document).ready(function () {
  mediumZoom('[data-zoomable]')

  confirm_rails();
  window.jQuery("#phone_no").mask("(999) 999-9999");
  window.jQuery(".phone_no_masked").mask("(999) 999-9999");
  window.jQuery(".ssn_masked").mask("999-99-9999", { autoclear: false });
  $('.dropdown-toggle').dropdown();

  $('.card-body-scroll').animate({ scrollTop: 20000 }, 'slow')
  window.jQuery(function () {
    window.jQuery('[data-toggle="tooltip"]').tooltip()
    window.jQuery('[data-toggle="collapse"]').collapse()

    })

  window.jQuery('DOMContentLoaded', () => {
    window.jQuery('.product_list').select2()
    window.jQuery('.srch').select2()
    // window.jQuery('.product_list').select2()
  })

  window.jQuery(".js-example-placeholder-single").select2({
    placeholder: "Please Type in All Desired Glove Size.",
    allowClear: true
  });
  window.jQuery(".search").select2({
    placeholder: "Please choose any option.",
    allowClear: true
  });

  $(function () {
  // window.jQuery('#sortable').sortable()

      window.jQuery('[data-toggle="toggle"]').bootstrapToggle();
    })
  $(function () {
    window.jQuery(".datepicker").datepicker({ startDate: '-1d' })
    window.jQuery(".datepicker_tday").datepicker({
      format: "mm/dd/yyyy"
    })
  // window.jQuery(".datetimepicker1").datetimepicker()
  // window.jQuery("#sortable").sortable()

    })
  $(function () {
    window.jQuery(".datepicker_elig").datepicker({
      format: "yyyymmdd"
    })
  })
  $(function () {
    window.jQuery(".subscriber_dt_picker").datepicker({
      format: "mm/dd/yyyy"
        }).on("changeDate", function (e) {
          // console.log("e => ",e.date)
          let subsc_dt = e.date;
          let dt_formatted = moment(subsc_dt).utcOffset('-0700').format('MM/DD/YYYY');

          let dt_formatted_str = moment(subsc_dt).utcOffset('-0700').format('YYYYMMDD')
          $('#subscriber_dt').html(`<input type="hidden" name="case_card[patient_data][subscriber][dateOfBirthVal]" value="${dt_formatted}" /><input type="hidden" name="case_card[patient_data][subscriber][dateOfBirth]" value="${dt_formatted_str}" />`)
        });
    });
  $(function () {
    window.jQuery(".dependent_dt_picker").datepicker({
      format: "mm/dd/yyyy"
        }).on("changeDate", function (e) {
          // console.log("e => ",e.date)
          let depn_dt = e.date;
          let dt_formatted = moment(depn_dt).utcOffset('-0700').format('MM/DD/YYYY')
          let dt_formatted_str = moment(depn_dt).utcOffset('-0700').format('YYYYMMDD')
          $('#dependent_dt').html(`<input type="hidden" name="case_card[patient_data][dependents][][dateOfBirthVal]" value="${dt_formatted}" /><input type="hidden" name="case_card[patient_data][dependents][][dateOfBirth]" value="${dt_formatted_str}" />`)
        })
    });
  /*$(function () {
    $(".dt_loc_time_picker").each(function(){
      let db_time = $(this).attr('data-dbtime');
      if (db_time != ''){
        $(this).datetimepicker({
          format:'LT',
          date: moment(db_time)
        });
      } else {
        $(this).datetimepicker({
          format:'LT'
        });
      }
    })
  });*/
  // if ($('.datepicker').length > 0){
  //    $('.datepicker').datepicker({
  //      format: 'yyyy/mm/dd',
  //      startDate: '-3d'
  //    });
  //  }

  function update_implant_data(case_card_id) {
    $.ajax({
      type: "PATCH",
      data: {
        case_card: { include_implants: $('input[name="include_implants"]:checked').val() }
      },
      url: `/case_cards/${case_card_id}/save_reconciliation`,
        }).done(function (result) {
          console.log(result);
        });
    }

  function update_implant_toggle(imp_pos, case_card_id, checked_state) {
    $.ajax({
      type: "PATCH",
      data: {
        case_card: { is_checked: checked_state, implant_position: imp_pos }
      },
      url: `/case_cards/${case_card_id}/save_implant_toggle`,
        }).done(function (result) {
          window.jQuery.toast({
            heading: 'Notice',
            text: 'Implant updated successfully.',
            position: 'top-right',
            loaderBg: '#1283da',
            icon: 'info',
            hideAfter: 5000,
            stack: 6
          });
        });
    }


  $(function () {
    window.jQuery('.used_notused').bootstrapToggle({
      on: 'Used',
      off: 'Not Used',
      offstyle: 'danger',
      onstyle: 'success',
        }).on('change', function (e) {
          let imp_pos = $(this).attr('data-implant-position');
          let case_card_id = $(this).attr('data-case-card-id');
          update_implant_toggle(imp_pos, case_card_id, $(this).prop('checked'));
        });
    });

  $(function () {
    window.jQuery('.music_allowed').bootstrapToggle({
      on: 'Yes',
      off: 'No',
      offstyle: 'danger'
            }).on('change', function () {
              if ($(this).prop('checked')) {
                $('.muzic_detail').removeAttr('hidden');
              } else {
                $('.muzic_detail').find('input').val('');
                $('.muzic_detail').attr('hidden', 'hidden');
              }
            });


      window.jQuery('.include_implant').bootstrapToggle({
        on: 'Yes',
        off: 'No',
        offstyle: 'danger'
            }).on('change', function (e) {
              let casecard_id = $(this).attr('data-case-card-id');
              update_implant_data(casecard_id);
              if ($(this).prop('checked')) {
                $(`.add_reconcilation_implant`)[0].click();
              } else {
                window.location.reload();
              }
            })

      window.jQuery('.present_not_present').bootstrapToggle({
        on: 'Present',
        off: 'Not Present',
        offstyle: 'danger'
      });

      window.jQuery('.staff_present_toggle').bootstrapToggle({
        on: 'Present',
        off: 'Not Present',
        offstyle: 'danger'
      });

      window.jQuery('.checkbox_list').bootstrapToggle({
        on: 'Viewed',
        off: 'Not Viewed',
        offstyle: 'danger'
      });
      window.jQuery('.read_unread').bootstrapToggle({
        on: 'Read',
        off: 'Unread',
        offstyle: 'danger'
      });
      window.jQuery(".date_time_format").bootstrapToggle({
        on: "American",
        off: "European",
            }).on('change', function (e) {
              let c_elem = $(this);
              console.log("checkedval ===", $(this).prop('checked'))
              if ($(this).prop('checked')) {
                $("#private_info_form .hiddenform_area").html(`<input type="hidden" name="user[date_time_format]" value="on" />`)
              } else {
                $("#private_info_form .hiddenform_area").html(`<input type="hidden" name="user[date_time_format]" value="off" />`)
              }
            })
      window.jQuery('.open_close').bootstrapToggle({
        on: 'Open',
        off: 'Close',
        offstyle: 'danger'
      });
      window.jQuery('.reopen_close').bootstrapToggle({
        on: 'Reopen',
        off: 'Close',
        offstyle: 'danger'
      });
      window.jQuery('.approved_decline').bootstrapToggle({
        on: 'Approved',
        off: 'Reject',
        offstyle: 'danger'
      });
      window.jQuery('.checkbox_yes_no').bootstrapToggle({
        on: 'No',
        off: 'Yes',
        offstyle: 'danger'
      });
      window.jQuery('.checkbox_toggle_yes_no').bootstrapToggle({
        on: 'Yes',
        off: 'No',
        offstyle: 'danger'
      });
      window.jQuery('.active_inactive').bootstrapToggle({
        on: 'Active',
        off: 'Inactive',
        offstyle: 'danger'
      });
      window.jQuery('.caseCard_active_inactive').bootstrapToggle({
              off: 'Advanced',
              on: 'Simple',
              offstyle: 'success'
            });
      window.jQuery('.notify_on_off').bootstrapToggle({
        on: 'On',
        off: 'Off',
        offstyle: 'danger'
      });
      window.jQuery('.rep_office_yes_no').bootstrapToggle({
        on: 'Active',
        off: 'Archived',
        offstyle: 'danger'
      });
    })
  /* $('.gaTrack').on('click', function() {
    var name = $(this).attr('data-elem-name');
    gtag('event', 'button_click', {
      'event_category': 'Link',
      'event_label': name
    });
  }); */
});


$(document).ready(function () {
  //attach csrf token with every ajax
  $.ajaxSetup({
    'global': true,
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
    });

  $(document).ajaxStart(function () {
    $('.pills_tab_content').html(`<div class="text-center"><i class="fa fa-spinner fa-spin fa-3x fa-fw text-center"></i></div>`);
  });


  $('a.bstooltip').on('mouseover', function () {
    $(this).focus();
  });
  $('a.bstooltip').on('mouseleave', function () {
    $(this).blur();
    $('[data-toggle="tooltip"]').tooltip('hide');
  });


  $.each($("a[data-remote='true']:not([data-paging])"), function (i, val) {
    $(val).data("url", $(val).attr("href")).attr("href", "javascript://")
  });

  window.jQuery.rails.href = function (el) {
    var $el = $(el);
    return $el.data('url') || $el.data('href') || $el.attr('href');
  }
});


function confirm_rails() {
  $(document).on('mousedown', 'a[data-confirm]', function (e) {
    let $ = window.$b;
    e.preventDefault();
    let link = window.jQuery(this)
    let message = link.data('confirm');
    let modal = window.jQuery("#modal_confirm");
    modal.find('.modal-body').text(message)
    let approve = modal.find('.approve')
    let approve_txt = link.attr('data-approve-text');
    approve.attr('data-method', link.data('method'));
    approve.attr('href', link.attr('href'));
    approve_txt == '' ? 'OK' : approve.text(approve_txt)
    modal.modal('show')
  });
}
