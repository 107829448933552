$(function() {
 $(".allow_decimal").on("input", function (evt) {
   var self = $(this);
   self.val(self.val().replace(/[^0-9\.]/g, ''));
   if ((evt.which != 46 || self.val().indexOf('.') != -1) && (evt.which < 48 || evt.which > 57)) {
     evt.preventDefault();
   }
 });
 $(".allow_integers").on("input", function (evt) {
   var self = $(this);
   self.val(self.val().replace(/[^0-9]/g, ''));
  //  if ((evt.which != 46 || self.val().indexOf('.') != -1) && (evt.which < 48 || evt.which > 57)) {
  //    evt.preventDefault();
  //  }
 });
});
