// $.fn.modal= window.$b.fn.modal;
let crop_max_width = 480;
let crop_max_height = 300;
let jcrop_api;
let canvas;
let context;
let image;
let prefsize;
let imgdiv;
let img_type = 'profile';

let imgMaxWidth = 600; // max width of image for cropping

// compress larger images and mantain quality
function compress(source_img_obj, quality, maxWidth, output_format){
  var mime_type = "image/jpeg";
  if(typeof output_format !== "undefined" && output_format=="png"){
      mime_type = "image/png";
  }

  maxWidth = maxWidth || 1000;
  var natW = source_img_obj.naturalWidth;
  var natH = source_img_obj.naturalHeight;
  var ratio = natH / natW;
  if (natW > maxWidth) {
      natW = maxWidth;
      natH = ratio * maxWidth;
  }

  var cvs = document.createElement('canvas');
  cvs.width = natW;
  cvs.height = natH;

  var ctx = cvs.getContext("2d").drawImage(source_img_obj, 0, 0, natW, natH);
  var newImageData = cvs.toDataURL(mime_type, quality/100);
  var result_image_obj = new Image();
  result_image_obj.src = newImageData;
  return result_image_obj.src;
}

$(function(){
  $(".changePictureBtn").on("click", function(e){
    e.preventDefault();
    $("#user_profile_picture:hidden").trigger('click');
  });
});

$(document).on('shown.bs.modal','#imageManipulateModal', function () {
  canvasCroping();
});
$(document).on('hidden.bs.modal','#imageManipulateModal', function () {
  $("input.user_profile_picture").val('');
});

function readURL(input) {
  if (input.files && input.files[0]) {
    let reader = new FileReader();

    reader.onload = function(e) {
      
      $('#image_box').attr('src', e.target.result);
    }

    reader.readAsDataURL(input.files[0]);
  }
}

// ------- Code for cropping image
$(document).on("change", ".user_profile_picture", function() {
  if($(this).attr('data-imgtype') == 'banner'){
    img_type = 'banner';
    imgMaxWidth = 1280;
  }
  //$(this).replaceWith('<input id="photograph" type="file" class="form-control">');
  $("#imageManipulateModal").modal('show');
  loadImage(this);
});

$('#imageManipulateModal').on('shown.bs.modal', function(e) {
  validateImage();
})

function loadImage(input) {

   if (input.files && input.files[0]) {
      var reader = new FileReader();
      canvas = null;
      reader.onload = function (e) {
        // imgdiv = e.target.result
        image = document.createElement('img');
        image.onload = function (e) {
          imgdiv = compress(image, 90, imgMaxWidth,'png');
          image.src = imgdiv
        }
        image.src = e.target.result;
      }

      reader.readAsDataURL(input.files[0]);
  }

  /*if (input.files && input.files[0]) {
    let reader = new FileReader();
    canvas = null;
    reader.onload = function(e) {
      image = new Image();
      // image.onload = function(e) {
      //   image.src = compress(image,90,1200,'png');
      // }
      image.src = e.target.result;
      
    }
    reader.readAsDataURL(input.files[0]);
  }*/
}

function dataURLtoBlob(dataURL) {
  let BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
    let parts = dataURL.split(',');
    let contentType = parts[0].split(':')[1];
    let raw = decodeURIComponent(parts[1]);

    return new Blob([raw], {
      type: contentType
    });
  }
  let parts = dataURL.split(BASE64_MARKER);
  let contentType = parts[0].split(':')[1];
  let raw = window.atob(parts[1]);
  let rawLength = raw.length;
  let uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], {
    type: contentType
  });
}

function validateImage() {
  if (canvas != null) {
    image = new Image();
    image.onload = affixJcrop;
    image.src = canvas.toDataURL('image/png');
  } else affixJcrop();
}

function affixJcrop() {
  
  if (jcrop_api != null) {
    jcrop_api.destroy();
  }
  $("#views").empty();
  $("#views").append("<canvas id=\"canvas\">");
  canvas = $("#canvas")[0];
  context = canvas.getContext("2d");
  
  canvas.width = image.width;
  canvas.height = image.height;
  context.drawImage(image, 0, 0);
  canvasCroping();
  clearcanvas();
}

function canvasCroping(){
  aspRatio = 1;
  if (img_type == 'banner'){
    aspRatio = 3.2;
  }
  window.jQuery("#canvas").Jcrop({
    onSelect: selectcanvas,
    onRelease: clearcanvas,
    boxWidth: crop_max_width,
    boxHeight: crop_max_height,
    minSize: [100, 100],
    aspectRatio: aspRatio,
    setSelect: [0, 0, 1263, 421]

  }, function() {
    jcrop_api = this;
  });
}

function clearcanvas() {
  prefsize = {
    x: 0,
    y: 0,
    w: canvas.width,
    h: canvas.height,
  };
}

function selectcanvas(coords) {
  prefsize = {
    x: Math.round(coords.x),
    y: Math.round(coords.y),
    w: Math.round(coords.w),
    h: Math.round(coords.h)
  };
}

function applyCrop() {
  canvas.width = prefsize.w;
  canvas.height = prefsize.h;
  
  context.drawImage(image, prefsize.x, prefsize.y, prefsize.w, prefsize.h, 0, 0, canvas.width, canvas.height);
  
  validateImage(affixJcrop);
}

// submit location form images
// location profile image and banner image
$("#cropLocForm").submit(function(e) {
  e.preventDefault();

  applyCrop();

  let l_blob;
  let lformData = new FormData($(".celebLocForm")[0]);
  
  if (canvas != null){
    l_blob = dataURLtoBlob(canvas.toDataURL('image/png'));
    // console.log(image.src)
    if (img_type == 'banner'){
      $(".locBannerBox").removeAttr('hidden');
      $('.banner_box').find('.banner_picker').removeClass('col-md-12').addClass('col-md-9');
      $('.banner_box').append('<input type="hidden" name="location[banner]" value="'+image.src+'">');
      $(".bannerpic").attr('src', canvas.toDataURL('image/png'));
    } else {
      $(".locImageBox").removeAttr('hidden');
      $('.image_box').find('.loc_image_picker').removeClass('col-md-12').addClass('col-md-9');
      $('.image_box').append('<input type="hidden" name="user[avatar]" value="'+image.src+'">');
      // $('.image_box').append("user[avatar]", l_blob);
      $(".imagepic").attr('src', canvas.toDataURL('image/png'));
    }
  }
  
  
  $('#imageManipulateModal').modal("hide");
  // $("#location_banner")
});

// crop user profile image
// from edit profile area
$("#cropForm").submit(function(e) {
  e.preventDefault();

  applyCrop();

  let blob;
  $sub = $("#submit_btn");
  $sub.find('span').addClass("fa fa-spin fa-cog");
  $sub.attr("disabled", "disabled");
  
  $(".image_box").find('.hidden').removeClass('hidden');
  $(".image_box").find('.col-12').removeClass('col-12').addClass('col-10');
  

  formData = new FormData($("#cropForm")[0]);
  if (canvas != null){
    blob = dataURLtoBlob(canvas.toDataURL('image/png'));
    console.log(blob)
    formData.append("user[image]", blob);
  }    
  //---Add file blob to the form data
  $.ajax({
    url: "/crop_user_image",
    type: "POST",
    data: formData,
    contentType: false,
    cache: false,
    processData: false,
    dataType: 'json',
    beforeSend: function(response){           
      // $(".userProfilePic").attr('src', '/assets/buffering.gif');
      $("#submit_btn").find('span').addClass("fa fa-spin fa-cog");
    },
    success: function(data) {
      $("input.user_profile_picture").val('');
      $(".userProfilePic").attr('src', canvas.toDataURL('image/png'));
    },
    error: function(data) {
      // alert
      alert();
      // $(".userProfilePic").removeAttr('src', '/assets/buffering.gif');
      $("#submit_btn").find('span').removeClass("fa fa-spin fa-cog");
      // $('#imageManipulateModal').modal("hide");
      $sub.removeClass("disabled");
      alert("Error! not able to upload image.");
    },
    complete: function(data) {
      $("#submit_btn").find('span').removeClass("fa fa-spin fa-cog");
      $('#imageManipulateModal').modal("hide");
      $sub.removeClass("disabled");

    }
  });
});
