import consumer from "./consumer"
// consumer.subscriptions.create({ channel: "RoomChannel", room: $(`body`).attr('data-curr-user-id') }, {
consumer.subscriptions.create("RoomChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    $('.card-body-scroll').animate({scrollTop:20000}, 'slow')
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    let elem = $('[data-channel-subscribe="user"]');
    let casecard_id = $(elem).attr('data-case-card');
    
    let selectd_usr_id = $(`.user_list_aside`).attr(`data-selected-user-id`);

    let receiverId = $(elem).attr('data-reciver-id');
    
    let curr_user = data.userid;

    let msgData = data.message;

    let notifyUser = $("body").attr('data-curr-user-id');

    // send notifcation alert message to receiver
    if (msgData.receiver_id == parseInt(notifyUser)){
      window.jQuery.toast({
        heading:`New Message from ${data.message_from}`,
        text: `${msgData.message}`,
        position: 'top-right',
        loaderBg: '#1283da',
        icon: 'info',
        hideAfter: 10000,
        stack: 6
      });
    }
    
    // add message to chat box when box is opened
    let rowClass, cardClass, cardBodyClass;
    let apnded_html = '';
    if (casecard_id != undefined) {
      apnded_html = `<b>${data.message_from}</b><br>`;
    }

    if ((msgData.sender_id == parseInt(receiverId)) || (casecard_id != undefined && msgData.sender_id != parseInt(notifyUser))){
      rowClass = "justify-content-start";
      cardClass = "bg-light";
      cardBodyClass = "message-content";
    } else {
      rowClass = "justify-content-end text-right";
      cardClass = "bg-primary text-white";
      cardBodyClass = "";
    }
    let content = `<div class="row ${rowClass} my-2 chat-message-container">`+
                `<div class="col-auto" style="max-width:60%;">`+
                  `<div class="card ${cardClass}">`+
                    `<div class="card-body p-2 ${cardBodyClass}">`+
                      `<p class="mb-0">`+
                        apnded_html +
                        msgData.message +
                      `</p>`+
                      `<div>`+
                        `<small>${data.dt_time}</small>`+
                      `</div>`+
                    `</div>`+
                  `</div>`+
                `</div>`+
              `</div>`;

    // if ((msgData.receiver_id == parseInt(notifyUser)) || (msgData.sender_id == parseInt(notifyUser))){
      if (msgData.case_card_id == null) {
        
        if(msgData.sender_id == parseInt(notifyUser) && msgData.receiver_id === parseInt(receiverId)){
          $(`.user_list_aside`).html(data.current_user_aside);
          elem.append(content);
        }
        if (msgData.receiver_id === parseInt(notifyUser)) {
          $(`.user_list_aside`).html(data.receiver_user_aside);
        }
        if(msgData.sender_id == parseInt(receiverId) && msgData.receiver_id === parseInt(notifyUser)){
          elem.append(content);
        }

        setTimeout(() => {
          let usr_list = $(`.user_list_aside a`);
          for (let i = 0; i < usr_list.length; i++) {
            let u_a_elem = usr_list[i];
            let u_a_id = $(u_a_elem).attr('data-msg-user-id');
            if (u_a_id == selectd_usr_id) {
              $(u_a_elem).addClass('active');
            } else {
              $(u_a_elem).removeClass('active');
            }
          }
        },50)
      } else {
        // $(`[data-case-card="${casecard_id}"]`).append(content);
        $(`[data-case-card="${msgData.case_card_id}"]`).append(content);
      }


    // }
    elem.animate({ scrollTop: elem.prop("scrollHeight")}, 1000);
    $('#msg').val('');
    // scroll box to bottom when message is appended
    $('.card-body-scroll').animate({scrollTop:20000}, 'slow')

    // Called when there's incoming data on the websocket for this channel
  }
});
